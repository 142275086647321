import React from "react";
import { Grid, Paper, FormControl, InputLabel, Select, MenuItem, Typography } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { nextId } from "../../libs/common";

function CountryAffiliationsUI(props) {
	const { classes, initialValues, countries } = props;
	return (
		<Grid container key={nextId()} direction="row" justify="space-evenly" alignItems="center">
			<Grid key={nextId()} item xs={12}>
				<Paper className={classes.paper} style={{ boxShadow: "0px 20px 40px rgba(0,0,0,0.06)" }}>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<Grid item xs={12}>
								<br />
								<Typography variant="h6" color="textPrimary" align="left">
									Country Affiliation
								</Typography>
								<Typography variant="body2" color="primary" align="left">
									These fields cannot be changed.  Please contact our support desk if these fields are incorrect.
								</Typography>
							</Grid>

							<FormControl margin="normal" fullWidth required>
								<InputLabel htmlFor="active">Country of Origin</InputLabel>
								<Select
									disabled
									value={initialValues.country_of_origin_id}
									onBlur={props.handleBlur}
									label="Country of Origin"
									required
									style={{ textAlign: 'left' }}
								>
									{makeMenuItems({ data: countries.toJSON(), valueField: "id", keyIdField: "id", keyPrefix: "cor-", textField: "name", sortBy: "name" })}
								</Select>
							</FormControl>

							<FormControl margin="normal" fullWidth required>
								<InputLabel htmlFor="active">Country of Residence</InputLabel>
								<Select
									disabled
									value={initialValues.country_of_residence_id}
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									label="Product Type"
									required
									style={{ textAlign: 'left' }}
								>
									{makeMenuItems({ data: countries.toJSON(), valueField: "id", keyIdField: "id", keyPrefix: "cor-", textField: "name" })}
								</Select>
							</FormControl>

						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Grid>
	);
}

function makeMenuItems({ data, valueField, keyPrefix, keyIdField, textField, sortBy }) {
	let _data = data;
	if (sortBy) _data = data.sort((a, b) => a[sortBy] > b[sortBy] ? 1 : -1);
	let result = _data.map(a => (
		<MenuItem value={a[valueField]} key={`${keyPrefix}-${a[keyIdField]}`}>
			{a[textField]}
		</MenuItem>
	));
	return result;
}

export default inject("routing")(observer(CountryAffiliationsUI));
