import React from 'react';
import { Grid, Typography, IconButton, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ArrowForwardIos';
import configTheme from '../../config/theme';

export default function Lines(options, navigateTo, classes, hashVariable, iconFn) {
	return (
		<React.Fragment>
			<Grid item xs={12}>
				<Table padding="none">
					{options.length === 0 ? (
						<TableBody>
							<TableRow>
								<TableCell style={{ textAlign: 'center', width: 48 }}>&nbsp;</TableCell>
							</TableRow>
							<TableRow>
								<TableCell style={{ textAlign: 'center', width: 48 }}>
									<Typography variant="body1">Nothing to show</Typography>
								</TableCell>
							</TableRow>
						</TableBody>)
						: null}
					{options.map((item, idx) =>
						(<TableBody key={`${hashVariable}-${idx}`} style={{borderLeft: `5px solid ${configTheme.portfolioStatusColours[item.status]}`}}>
							<TableRow className={classes.green} hover onClick={() => navigateTo(hashVariable, item.id)}>
								<TableCell style={{ textAlign: 'center', width: 48 }}>{iconFn(item)}</TableCell>
								<TableCell style={{ textAlign: 'left' }}>{item.name}</TableCell>
								<TableCell style={{ textAlign: 'right' }}>{item.interest_rate}%</TableCell>
								<TableCell style={{ textAlign: 'right' }}>{item.tenor} days</TableCell>
								<TableCell style={{ textAlign: 'right', width: 48 }}>
									<IconButton onClick={() => navigateTo(hashVariable, item.id)}>
										<ChevronRight className={classes.icon} />
									</IconButton>
								</TableCell>
							</TableRow>
						</TableBody>))}
				</Table>
			</Grid>
		</React.Fragment>);
}
