import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/styles";
import configTheme from "../../config/theme";
import FileUploader from "./FileUploader";
import { AppCard } from '../OptionSelector';

function KYCDocuments(props) {
	const { classes } = props;
	return (
		<>
			<AppCard classes={classes} spacing={4}>
				<Grid container spacing={4}>

					<Grid item xs={12} spacing={4}>
						<Typography variant="h6">Document: ID</Typography>
						<Typography variant="body1" style={{color:'#999'}}>
							Use this button to Upload your Identification document.
						</Typography>
						<FileUploader text="Upload Id" classes={classes} fileType="photo-id" />
					</Grid>

				</Grid>
			</AppCard>

			<AppCard classes={classes} spacing={4}>
				<Grid container spacing={4}>

					<Grid item xs={12}>
						<Typography variant="h6">Document: Address</Typography>
						<Typography variant="body1" style={{color:'#999'}}>
							Use this button to upload your proof of address.
						</Typography>
						<FileUploader text="Upload Bill" classes={classes} fileType="bill" />
					</Grid>

				</Grid>

			</AppCard>
		</>
	);
}


const styles = theme => ({
	button1: configTheme.darkButton,
	button2: configTheme.lightButton,
	menuButton: {
		marginLeft: 12,
		marginRight: 12,
	},
	paper: {
		padding: 16,
		margin: 8,
		textAlign: 'center',
		// color: accountTileNameText,
	},
	button: {
		...configTheme.lightButton,
		paddingTop: 16,
	},
	icon: {
		color: 'rgb(130,202,64)',
		fontSize: 100
	},
	textbox: {
		marginTop: 8,
	},
	text: {
		color: '#999',
		textAlign: 'center',
		padding: 8,
	},
});



export default withStyles(styles, { withTheme: true })(inject("routing")(observer(KYCDocuments)));
