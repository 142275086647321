import React from "react";
import { Grid, Paper, FormControl, InputLabel, Select, MenuItem, Button, Typography } from "@material-ui/core";
import { Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import { inject, observer } from "mobx-react";
import { nextId } from "../../libs/common";
import { withStyles } from "@material-ui/styles";
import configTheme from "../../config/theme";

function ProfileEditUI(props) {
	const { classes, initialValues, countries } = props;
	console.log(countries);
	return (
		<Grid container key={nextId()} direction="row" justify="space-evenly" alignItems="center">
			<Grid key={nextId()} item xs={12}>
				<Paper className={classes.paper} style={{ boxShadow: "0px 20px 40px rgba(0,0,0,0.06)" }}>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<Formik
								initialValues={initialValues}
								onSubmit={(values, actions) => {
									props.onSubmit(values);
								}}
								render={props => {
									return (
										<form onSubmit={props.handleSubmit}>

											<Grid container>
												<Grid item xs={12}>
													<Typography variant="h6" color="textPrimary" align="left">
														Person Details
													</Typography>
												</Grid>
											</Grid>

											<TextField
												className={classes.textFieldContainer}
												InputProps={{ classes: { input: classes.first_name } }}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												value={props.values.first_name}
												name="first_name"
												label="First Name"
												margin="normal"
												required
												type="text"
												fullWidth
											/>

											<TextField
												className={classes.textFieldContainer}
												InputProps={{ classes: { input: classes.last_name } }}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												value={props.values.last_name}
												name="last_name"
												label="Last Name"
												margin="normal"
												required
												type="text"
												fullWidth
											/>

											<TextField
												className={classes.textFieldContainer}
												InputProps={{ classes: { input: classes.dob } }}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												value={props.values.date_of_birth || ' '}
												name="date_of_birth"
												label="Date of Birth"
												margin="normal"
												required
												type="date"
												fullWidth
											/>

											<TextField
												className={classes.textFieldContainer}
												InputProps={{ classes: { input: classes.email } }}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												value={props.values.email}
												name="email"
												label="Email"
												margin="normal"
												required
												type="text"
												fullWidth
											/>

											<Grid container>
												<Grid item xs={4}>
													<FormControl margin="normal" fullWidth required>
														<InputLabel htmlFor="active">Country</InputLabel>
														<Select
															value={props.values.mobile_country_id || ''}
															onChange={props.handleChange}
															onBlur={props.handleBlur}
															name="mobile_country_id"
															label="Country"
															required
															inputProps={{
																name: "mobile_country_id",
																id: "mobile_country_id"
															}}
														>
															{makeMenuItems({
																data: countries.toJSON(),
																valueField: "id",
																keyIdField: "id",
																keyPrefix: "dialing_code-",
																textField: "dialing_code",
																sortBy: "dialing_code",
															})}
														</Select>
													</FormControl>
												</Grid>
												<Grid item xs={1} />
												<Grid item xs={6}>
													<TextField
														className={classes.textFieldContainer}
														InputProps={{ classes: { input: classes.mobile_number } }}
														onChange={props.handleChange}
														onBlur={props.handleBlur}
														value={props.values.mobile_number}
														name="mobile_number"
														label="Mobile Number"
														margin="normal"
														required
														type="number"
														fullWidth
													/>
												</Grid>
											</Grid>

											<TextField
												className={classes.textFieldContainer}
												InputProps={{ classes: { input: classes.textField } }}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												value={props.values.address}
												name="address"
												label="Address"
												margin="normal"
												required
												type="text"
												fullWidth
												rows={4}
												multiline
											/>

											<br />

											<Button className={classes.button2} variant="contained" fullWidth onClick={props.handleSubmit}>
												Submit
											</Button>
										</form>
									);
								}}
							/>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Grid>
	);
}

function makeMenuItems({ data, valueField, keyPrefix, keyIdField, textField, sortBy }) {
	let _data = data;
	if (sortBy) _data = data.sort((a, b) => a[sortBy] > b[sortBy] ? 1 : -1);
	let result = _data.map(a => (
		<MenuItem value={a[valueField]} key={`${keyPrefix}-${a[keyIdField]}`}>
			{a[textField]}
		</MenuItem>
	));
	return result;
}

const styles = theme => ({
	menuButton: {
		marginLeft: 12,
		marginRight: 12,
	},
	button1: configTheme.darkButton,
	button2: configTheme.lightButton,
});


export default withStyles(styles, { withTheme: true })(inject("routing")(observer(ProfileEditUI)));
