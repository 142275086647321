import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { OptionSelector3 } from '../OptionSelector';
import { sendNotification, urlToHashParams, hashParamsToURL, numberWithCommas, calculatePortfolioTotals, getBaseLocation, formatValue } from '../../libs/common';
import { toJS } from 'mobx';
import { inject, observer } from "mobx-react";
import HUE from '@material-ui/core/colors/grey';
import configTheme from '../../config/theme';
import PortfolioItemEdit from './PortfolioItemEdit';
import PortfolioOverview from './PortfolioOverview';

const accountTileNameText = HUE[400];

class PortfolioContainer extends React.Component {
	state = {
		localHistory: [getBaseLocation()],
		baseURL: getBaseLocation(),
		basePath: 'portfolio',
	}

	navigateTo = (key, value) => {

		const hashParams = urlToHashParams(window.location.hash);

		hashParams[key] = value;

		const currentState = hashParamsToURL(this.state.baseURL, hashParams);

		window.history.replaceState(null, null, currentState);
		this.setState({ localHistory: [...this.state.localHistory, currentState] },
		);
	}

	navigateBack = () => {

		if (this.state.localHistory.length) {
			let value = this.state.localHistory.pop();

			if (value === window.location.href) {
				return this.navigateBack();
			}

			window.history.replaceState(null, null, value);
			this.setState({ localHistory: [...this.state.localHistory, value] },
			);
		} else {
		}
	}

	resetState = () => {
		this.setState({ localHistory: [getBaseLocation()] });
		return null;
	}

	onAddInvestment = () => {
		this.props.routing.push('invest');
	}

	onSubmitEdit = async (values) => {
		const result = await this.props.portfolio.update(values);
		if (result === true) {
			sendNotification('Saved');
			// window.history.back();
		}
	}

	getPortfolioDetails(_id) {
		const id = parseInt(_id);
		let item = this.props.portfolio.data.find(a => a.id === id);
		if (item) item = toJS(item);
		return item;
	}

	async componentDidMount() {
		await Promise.all(['currencies', 'portfolio', 'financialInstitutions'].map(a => this.props[a].check()));
	}

	render() {
		const { classes, financialInstitutions, portfolio, currencies } = this.props;
		const fisLogos = financialInstitutions.data.reduce((a, c) => Object.assign(a, { [c.id]: c.url_logo }), {});
		const hashParams = urlToHashParams(window.location.hash);

		// test for the special case where the user uses some other navigation path to skip right
		// back to the baseUrl, leaving localHistory with ancient history
		if (Object.keys(hashParams).length === 0 && this.state.localHistory.length > 1) {
			return <this.resetState />;
		}

		// ['processing', 'booked', 'matured', 'liquidated']

		if (!hashParams.portfolioId) {
			let portfolioItems = portfolio.data;

			const options = portfolioItems.map(a => {
				return ({
					id: a.id,
					text: `${a.productJSON.name}:\t${a.productJSON.interest_rate}%,\t${a.productJSON.tenor} days`,
					name: a.productJSON.name,
					interest_rate: a.productJSON.interest_rate,
					tenor: a.productJSON.tenor,
					disabled: false,
					fis_id: a.productJSON.issuing_fi,
					status: a.status
				});
			});

			portfolioItems = portfolioItems.map(a => Object.assign({}, a, { amount: formatValue(a.amount) }));
			const portfolioTotals = calculatePortfolioTotals(portfolioItems);
			const overviewText = {
				headerText: `${portfolioTotals.currency}${numberWithCommas(portfolioTotals.returnsTotal)}`,
				returnsEarnedText: `${portfolioTotals.currency}${numberWithCommas(portfolioTotals.returnsEarned)}`,
				returnsDueText: `${portfolioTotals.currency}${numberWithCommas(portfolioTotals.returnsDue)}`,
			};

			const iconFn = (id) => {
				return <img style={{ height: 32, width: 32 }} alt="" src={fisLogos[id.fis_id]} width="32" />;
			}

			return (
				<React.Fragment>
					<PortfolioOverview classes={classes} {...overviewText} />
					<OptionSelector3 navigateTo={this.navigateTo} onPlus={this.onAddInvestment} iconFn={iconFn} options={options} classes={classes} title="Investments" hashVariable="portfolio-id" />
				</React.Fragment>
			);
		}

		if (hashParams.portfolioId) {
			let portfolioId;
			if (hashParams.portfolioId === 'latest') {
				portfolioId = portfolio.data.reduce((a, c) => c.id > a ? c.id : a, 0);
			} else {
				portfolioId = hashParams.portfolioId;
			}
			let initialValues = this.getPortfolioDetails(portfolioId);
			if (!initialValues) return null;

			// calculate profit
			initialValues.amount = formatValue(initialValues.amount);
			const maturityDate = new Date(initialValues.date_created);
			maturityDate.setDate(maturityDate.getDate() + initialValues.productJSON.tenor);
			const dateOfMaturity = maturityDate.toJSON().substr(0, 10);
			const annualisedReturn = (initialValues.amount / 100) * parseFloat(initialValues.productJSON.interest_rate);
			const returnOnMaturity = (annualisedReturn / 365) * initialValues.productJSON.tenor;
			const amountOfTax = (returnOnMaturity / 100) * initialValues.productJSON.tax_on_interest;
			const amountOnMaturity = initialValues.amount + returnOnMaturity - amountOfTax;
			const calculations = {
				onMaturity: {
					dateOfMaturity,
					returnOnMaturity: numberWithCommas(returnOnMaturity.toFixed(2)),
					amountOfTax: numberWithCommas(amountOfTax.toFixed(2)),
					amountOnMaturity: numberWithCommas(amountOnMaturity.toFixed(2)),
				}
			};
			Object.assign(initialValues, calculations);

			const product = initialValues.productJSON;
			const fis = Object.assign(
				{},
				financialInstitutions.data.find(a => a.id === parseInt(product.issuing_fi, 10)),
				{ issuing_fi_url_logo: fisLogos[product.issuing_fi] },
			);
			const currency = currencies.findByField('id', product.currency_id);

			return <PortfolioItemEdit title="Certificate" currency={currency} navigateTo={this.navigateTo} fis={fis} product={initialValues.productJSON} classes={classes} initialValues={initialValues} onSubmit={this.onSubmitEdit} onBack={this.navigateBack} />;
		}

		return <span>error</span>;
	}
}

const styles = theme => ({
	menuButton: {
		marginLeft: 12,
		marginRight: 12,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: accountTileNameText,
	},
	button1: configTheme.lightTrypticButton,
	button2: configTheme.lightButton,
	icon: {
		color: 'rgb(130,202,64)'
	},
});

export default withStyles(styles, { withTheme: true })(inject('routing', 'currencies', 'portfolio', 'financialInstitutions')(observer(PortfolioContainer)));
