import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from "mobx-react";
import { toJS } from 'mobx';
import HUE from '@material-ui/core/colors/grey';
import configTheme from '../../config/theme';
import CountryAffiliationsUI from './CountryAffiliationsUI';

const accountTileNameText = HUE[400];

class CountryAffiliationsContainer extends React.Component {
	async componentDidMount() {
		return Promise.all(['persons', 'countries'].map(a => this.props[a].check()));
	}

	render() {
		const { classes, persons, countries } = this.props;
		const initialValues = new persons.model(toJS(persons.person));
		return <CountryAffiliationsUI {...{ classes, initialValues, countries }} />;
	}
}

const styles = theme => ({
	menuButton: {
		marginLeft: 12,
		marginRight: 12,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: accountTileNameText,
	},
	button: {
		...configTheme.lightButton,
		marginTop: theme.spacing(2),
	},
	icon: {
		color: 'rgb(130,202,64)',
		fontSize: 100
	},
	textbox: {
		marginTop: theme.spacing(2),
	},
	text: {
		color: 'black',
		textAlign: 'center',
		padding: theme.spacing(2),
	}
});

export default withStyles(styles, { withTheme: true })(inject('persons', 'countries', 'routing')(observer(CountryAffiliationsContainer)));
