import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from "mobx-react";
import { sendNotification, toCamelCase, urlToHashParams, hashParamsToURL, arrayToIdx } from '../../libs/common';
import AdminPostFunds from './AdminPostFunds';

import HUE from '@material-ui/core/colors/grey';
import configTheme from '../../config/theme';

const accountTileNameText = HUE[400];

const styles = theme => ({
	menuButton: {
		marginLeft: 12,
		marginRight: 12,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: accountTileNameText,
		// width: 400,
	},
	button1: configTheme.lightTrypticButton,
	button2: configTheme.lightButton,
	icon: {
		color: 'rgb(130,202,64)'
	},
});

class AdminPostFundsContainer extends React.Component {
	state = {
		localHistory: [window.location.href.split('?')[0]],
		baseURL: window.location.href.split('?')[0],
		basePath: 'invest',
	}

	onBackToDashboard = () => {
		this.props.routing.push('dashboard');
	}

	onSubmitAdd = async (values) => {
		const ledgerData = {
			debit_account_id: values.fromAccount.id,
			credit_account_id: values.toAccount.id,
			amount: values.amount,
			description: values.description,
			audit_id: values.auditId,
		};
		const result = await this.props.transactions.create(ledgerData);

		if (result === true) {
			sendNotification('Transaction Committed');
			// TODO what happens next?
		}
	}

	navigateTo = (key, value) => {
		const hashKey = toCamelCase(key);
		const hashParams = urlToHashParams(window.location.hash);

		hashParams[hashKey] = value;

		const currentState = hashParamsToURL(this.state.baseURL, hashParams);

		window.history.replaceState(null, null, currentState);
		this.setState({ localHistory: [...this.state.localHistory, currentState] },
		);
	}

	navigateBack = () => {
		if (this.state.localHistory.length) {
			let value = this.state.localHistory.pop();

			if (value === window.location.href) {
				return this.navigateBack();
			}

			window.history.replaceState(null, null, value);
			this.setState({ localHistory: [...this.state.localHistory, value] },
			);
		} else {
		}
	}

	componentDidMount(){
		return new Promise(async (resolve)=>{
			await this.props.currencies.check();
			await this.props.accounts.check();
			await this.props.persons.check();
			await this.props.financialInstitutions.check();
			resolve();
		});
	}

	render() {
		const classes = this.props.classes;
		const initialValues = {
			fromPFI: 'person',
			fromEntity: '',
			fromAccount: '',
			toPFI: 'person',
			toEntity: '',
			toAccount: '',
			amount: 0,
			description: '',
			auditId: 0,
		};
		const data = {
			persons: this.props.persons,
			accounts: this.props.accounts,
			fis: this.props.financialInstitutions,
			currencyIdx: arrayToIdx(this.props.currencies.data, 'id', 'iso3'),
		};
		return <AdminPostFunds classes={classes} data={data} initialValues={initialValues} title='Post Transaction' onBack={this.navigateBack} onSubmit={this.onSubmitAdd} />;
	}
}

export default withStyles(styles, { withTheme: true })(inject('routing', 'accounts', 'currencies', 'financialInstitutions', 'persons', 'transactions')(observer(AdminPostFundsContainer)));
