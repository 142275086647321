import React, { useState } from "react";
import { observer, inject } from "mobx-react";
import { FormControl, Button } from "@material-ui/core";

const FileUploader = observer(props => {
	const [isuploading, setIsuploading] = useState(false);
	const store = props.persons;
	const { classes } = props;

	const submitFile = async (file) => {
		try {
			setIsuploading(true)
			const fileKey = await store.uploadDocument(props.fileType, file);
			await props.notifications.addToMessageQueue({type:'success', message:'Thanks You!  Document was uploaded'});
			await props.notifications.sendOther(`I have uploaded a file: ${fileKey}`);

		} catch (err) {
			console.error("submit file error", err);
			store.error = true;
			store.errorMessage = "Something went wrong while file upload.";
		}
		setIsuploading(false);
	};

	const handleChange = event => {
		event.persist();
		event.preventDefault();
		if (event.target.files.length) submitFile(event.target.files[0]);
	};

	const id = '' + new Date().valueOf();
	return (
		<FormControl fullWidth style={{paddingTop:16}}>
			<input
				type="file"
				accept="image/*,application/pdf"
				onChange={handleChange}
				id={id}
				style={{ display: 'none' }} />
			<label htmlFor={id}>
				<Button className={classes.button2} variant="contained" component="span" disabled={isuploading}>
					{isuploading ? 'Uploading...' : props.text}
				</Button>
			</label>
		</FormControl>
	);
});

export default inject("persons", "notifications")(FileUploader);
